import { render, staticRenderFns } from "./Widget2.vue?vue&type=template&id=a68a2d2a&"
import script from "./Widget2.vue?vue&type=script&lang=js&"
export * from "./Widget2.vue?vue&type=script&lang=js&"
import style0 from "./Widget2.vue?vue&type=style&index=0&id=a68a2d2a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports