<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div
        class="col-xxl-8"
        v-for="(session, index) in allSessions"
        :key="index"
      >
        <MixedWidget2 :url="session.url" :title="session.Title"></MixedWidget2>
        <MixedWidget2></MixedWidget2>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MixedWidget2 from "@/view/content/widgets/mixed/Widget2.vue";
import API from "../../core/services/api";

export default {
  name: "dashboard",
  components: {
    MixedWidget2
  },
  data() {
    return {
      allSessions: []
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Les sessions" }]);
    this.getAllSessions();
  },
  methods: {
    getAllSessions: async function() {
      const vm = this;
      return await API.getAllSessions().then(function(Response) {
        if (typeof Response.data["hydra:member"] !== "undefined") {
          vm.allSessions = Response.data["hydra:member"];
        }
      });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
